<template>
  <v-container class="px-4" id="menu-order-delivery-methods">
    <delivery-methods />
  </v-container>
</template>
  
  <script>
import DeliveryMethods from "@/components/menu/order/deliveryMethod/DeliveryMethods";

export default {
  name: "MenuOrderDetailsPaymentDeliveryMethods",
  provide: {
    heading: { align: "start" },
  },

  components: {
    DeliveryMethods,
  },
};
</script>
  