<template lang="">
  <div>
    <div
      v-if="isWebOrdersModeEnabled"
      class="text-subtitle-1 primary--text font-weight-bold pb-2"
    >
      Seleccionar la forma de entrega: {{ getDeliveryMethodVerbose }}
    </div>
    <!--Retiro por local-->
    <v-sheet
      v-if="isWebOrdersModeEnabled"
      class="pa-6 d-flex deliveryMethod"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
      ref="pickup"
      @click="
        setDeliveryMethod('pickup');
        setDeliveryShippingCost(false);
      "
    >
      <v-row justify="left">
        <v-col cols="auto">
          <v-row no-gutters>
            <v-icon color="black" size="1.8rem">mdi-walk</v-icon>
          </v-row>
        </v-col>
        <v-col cols="auto" class="text-right">
          <span class="ml-2">Retiro en local</span>
        </v-col>
      </v-row>
    </v-sheet>
    <!--Retiro por local-->
    <!--Delivery-->
    <v-sheet
      v-if="isWebOrdersModeEnabled && getDeliveryZone && isDeliveryOpen"
      class="pa-6 d-flex deliveryMethod"
      rounded="lg"
      outlined
      shaped
      ref="delivery"
      @click="
        isDeliveryOpen && setDeliveryMethod('delivery');
        isDeliveryOpen && setDeliveryShippingCost(true);
      "
    >
      <v-row justify="left">
        <v-col cols="auto">
          <v-row no-gutters>
            <v-icon color="black" size="1.8rem">mdi-bike</v-icon>
          </v-row>
        </v-col>
        <v-col cols="auto" class="text-right">
          <span class="ml-2">Delivery</span>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet
      v-else-if="isWebOrdersModeEnabled && getDeliveryZone && !isDeliveryOpen"
      class="pa-6 d-flex deliveryMethod"
      color="#d7d7d7"
      rounded="lg"
      outlined
      shaped
    >
      <v-row justify="left">
        <v-col cols="auto">
          <v-row no-gutters>
            <v-icon color="black" size="1.8rem">mdi-bike</v-icon>
          </v-row>
        </v-col>
        <v-col cols="auto" class="text-right">
          <span class="ml-2">Delivery</span>
        </v-col>
      </v-row>
    </v-sheet>
    <!--Delivery-->
    <div
      v-if="isWebOrdersModeEnabled && getDeliveryZone && !isDeliveryOpen"
      class="textColorDisabled"
    >
      Delivery no está disponible en este momento.
    </div>
  </div>
</template>
<script>
import mobileCommon from "@/mixins/mobile";
import shopUtils from "@/mixins/shopUtils";

export default {
  mixins: [mobileCommon, shopUtils],
  computed: {
    getDeliveryMethod() {
      return this.$store.getters["deliveryCart/getDeliveryMethod"];
    },
    getDeliveryMethodVerbose() {
      switch (this.getDeliveryMethod) {
        case "delivery":
          return "Delivery";
        case "pickup":
          return "Retiro por local";
        default:
          return this.getDeliveryMethod;
      }
    },
    getDeliveryZone() {
      var deliveryZoneConfig = this.$store.state.menu.profile.deliveryZone;
      return deliveryZoneConfig?.enabled ?? true;
    },
  },
  watch: {
    getDeliveryMethod() {
      this.setGlowingEffect(this.getDeliveryMethod);
    },
  },
  mounted() {
    this.setDeliveryMethod("pickup");
    this.setDeliveryShippingCost(false);
    this.setHelpContactCenter(this.$store.state.menu.profile.helpContactCenter);
  },
  methods: {
    setGlowingEffect(deliveryMethod) {
      this.resetAllGlowingEffect();
      switch (deliveryMethod) {
        case "delivery":
          this.setGlowingEffectByDeliveryMethod("delivery", "delivery");
        case "pickup":
          this.setGlowingEffectByDeliveryMethod("pickup", "pickup");
      }
    },
    resetAllGlowingEffect() {
      this.resetGlowingEffect("delivery");
      this.resetGlowingEffect("pickup");
    },
    reset() {},
    resetGlowingEffect(ref) {
      let classes =
        "pa-6 d-flex deliveryMethod v-sheet v-sheet--outlined v-sheet--shaped theme--light elevation-4 rounded-lg white";
      const elRef = this.$refs[ref];
      if (elRef) elRef.$el.classList.value = [classes];
    },
    setGlowingEffectByDeliveryMethod(deliveryMethod, ref) {
      let classes =
        "pa-6 d-flex deliveryMethod v-sheet v-sheet--outlined v-sheet--shaped theme--light elevation-4 rounded-lg white";
      let selectedDeliveryMethodClass = " selectedDeliveryMethod";

      if (this.getDeliveryMethod == deliveryMethod)
        classes += selectedDeliveryMethodClass;
      const elRef = this.$refs[ref];
      if (elRef) elRef.$el.classList.value = [classes];
    },
    setDeliveryMethod(deliveryMethod) {
      this.$store.dispatch("deliveryCart/setDeliveryMethod", deliveryMethod);
    },
    setHelpContactCenter(helpContactCenter) {
      this.$store.dispatch(
        "deliveryCart/setHelpContactCenter",
        helpContactCenter
      );
    },
    setDeliveryShippingCost(delivery) {
      if (delivery && this.$store.state.menu.profile.isShippingCostEnabled) {
        let shippingCost = Number(this.$store.state.menu.profile.shippingCost);
        this.$store.dispatch("deliveryCart/setShippingCost", shippingCost);
      } else {
        this.$store.dispatch("deliveryCart/setShippingCost", 0);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.deliveryMethod {
  margin: 10px 0px;
  cursor: pointer;
}

.selectedDeliveryMethod {
  background-color: #fff !important;

  box-shadow: 0 0 10px #00a3e2 !important;
}

.textColorDisabled {
  color: #626262;
}
</style>